import React, { Component } from 'react';
import './Lifts-Main.css';
import Lifts from '../Lifts/Lifts';
import LiftMap from '../Lifts/LiftMap';

class LiftsMain extends Component {
    constructor () {
        super();
        this.state = {
        };
    }

render() {
return (
    <div className="App">
        <div>
            <LiftMap />
        </div>
        <div>
            <Lifts/>
        </div>
    </div>

);
}
}

export default LiftsMain
