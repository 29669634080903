import React, { Component } from 'react';
import './Summer.css';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { Parser } from 'htmlparser2';
import BikeParkTrails from './BikePark/BikeParkTrails';
// import xml2js from 'xml2js';

const LoadingIndicator = props => {
    return (
    <div style={{
        width: "100%",
        height: "100",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
        }}
    >
    <Loader type="ThreeDots" color="var(--liftopenbox)" height="50" width="50" />
    </div>
    ) 
}

class SummerTrails extends Component {
    constructor () {
        super();
        this.state = {
            vDate: '',
            vLifts: [],
            vWeather: [],
            loading: true,
            error: false,
            liftloading: true,
            lifterror: false,
            weatherloading: true,
            weathererror: false,
            search: null,
        };
    }

    componentDidMount() {
        // Step 1: Make the HTTP request
        axios.get('https://fn.wblift.app/api/?type=summer')
        .then(response => {
            const xml = response.data;
            // Step 2: Set up the HTMLParser2
            let liftjson = [];
            var LiftArray = [];
            
            const parser = new Parser({
                onopentag(name, attribs) {
                    // Handle opening tags
                    if (name === 'lift') {
                        // Add each lift to the liftjson
                        liftjson.push(attribs);
                    }
                },
                onend() {
                    // Processing the parsed lifts
                    liftjson.forEach((el, i) => {
                        if (el.status === "1"){ //Open
                            var StatIcon = <i className="fa-solid fa-circle-check"></i>
                        }
                        if (el.status === "0"){ //Closed
                            var StatIcon = <i className='fa-solid fa-circle-xmark'></i>
                        }
                        if (el.Mountain === "Whistler"){
                            LiftArray.push({
                                ID: i, 
                                Lift: el.name, 
                                Mountain: el.Mountain, 
                                MountainID: el.MountainID, 
                                Type: el.lift_type, 
                                RideTime: el.lift_ride_time, 
                                isOpen: el.status, 
                                StatusIcon: StatIcon
                            })
                        }
                    })

                    // Sorts Trails Array to A-Z
                    LiftArray.sort((a, b) => {
                        if (a.Lift < b.Lift) return -1;
                        if (a.Lift > b.Lift) return 1;
                        return 0;
                    });
                },
                onerror(error) {
                    // Handle errors
                    console.error(error);
                }
            }, { xmlMode: true });

            // Step 3: Parse the XML
            parser.write(xml);
            parser.end();
            
            // Adds information to Constructor States
            this.setState({ vLifts: LiftArray });
            this.setState({ liftloading: false })

        })
        .catch(error => {
            console.log(error);
            console.log("Error Here, sending Error to client");
            this.setState({ liftloading: false });
            this.setState({ lifterror: true})
        });



        // Step 1: Make the HTTP request
        axios.all([
            axios.get('https://fn.wblift.app/api/?type=weatherstation&id=160'),
            axios.get('https://fn.wblift.app/api/?type=weatherstation&id=210'),
            axios.get('https://fn.wblift.app/api/?type=weatherstation&id=201'),
        ])
        .then(axios.spread((obj1, obj2, obj3) => {
            // Step 2: Set up the HTMLParser2
            const objarray = [obj1.data, obj2.data, obj3.data];
            const WeatherArray = [];
        
            objarray.forEach((el, i) => {
                let weatherjson = null;
                
                const parser = new Parser({
                    onopentag(name, attributes) {
                        // Parse WeatherStation Name and ID
                        if (name === "weatherstation") {                            
                            weatherjson = { $: attributes, Data: [] };
                        }
                        // Parse WeatherStation Temperature Information
                        if (name === "data" && weatherjson) {
                            weatherjson.Data.push({ $: attributes });
                        }
                    }
                }, { decodeEntities: true });
        
                // Step 3: Parse the XML
                parser.write(el);
                parser.end();
        
                if (weatherjson) {
                    WeatherArray.push({
                        ID: i,
                        Location: weatherjson.$.name,
                        CurrentTemp: weatherjson.Data[0].$.temp + "°C",
                        FriendlyUpdated: weatherjson.Data[0].$.friendlyupdated,
                        Updated: weatherjson.Data[0].$.updated
                    });
                }
            });
            // Adds information to Constructor States
            this.setState({ vWeather: WeatherArray });
            this.setState({ weatherloading: false });
        }))
        .catch(error => {
            console.log(error);
            console.log("Error Here, sending Error to client");
            this.setState({ weatherloading: false });
            this.setState({ weathererror: true });
        });
    };
   

    render() {
        const { liftloading } = this.state;
        const { lifterror } = this.state;
        const { weatherloading } = this.state;
        const { weathererror } = this.state;
        var vLifts = this.state.vLifts;
        var vWeather = this.state.vWeather;



        const mapLifts = vLifts
        .map(function(vLifts){
            return(
                // Table of Trails
                <React.Fragment key={vLifts.ID}>
                <tr>
                    <th id="difficultyicon">{vLifts.StatusIcon}</th>
                    <th>{vLifts.Lift}</th>
                    <th>{vLifts.Mountain}</th>
                </tr>
               </React.Fragment>
            )
        })

        const mapWeather = vWeather
        .map(function(vWeather){
            return(
                // Table of Trails
                <React.Fragment key={vWeather.ID}>
                <tr>
                    <th>{vWeather.Location}</th>
                    <th>{vWeather.CurrentTemp}</th>
                    <th>{vWeather.Updated}</th>
                </tr>
               </React.Fragment>
            )
        })



        return (
            <div>
            <div className="today--section container">
                <div className="top--section container">
                    { lifterror ? 
                    // error is true:
                    <div className="columns top--section__box startbox">
                        <div className="column lifts--section">
                            <h4 id="error">Error gathering lift data...</h4>
                        </div>
                    </div>
                    :
                    // error is false:
                    <div className="columns top--section__box startbox">
                        <div className="column liftsend--section">
                        <h5>Lifts</h5>
                            <div id="tabsContainer" className="tabs"></div>
                            { liftloading ? <LoadingIndicator /> : 
                                <table>
                                    <thead>
                                        <tr>
                                            <th id="centerhead">Status</th>
                                            <th >Lift Name</th>
                                            <th >Mountain</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {mapLifts}
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>
                    }

                    { weathererror ? 
                    // error is true:
                    <div className="columns top--section__box endbox">
                        <div className="column lifts--section">
                            <h4 id="error">Error gathering lift data...</h4>
                        </div>
                    </div>
                    :
                    // error is false:
                    <div className="columns top--section__box endbox">
                        <div className="column liftsend--section">
                        <h5>Current Weather</h5>
                            <div id="tabsContainer" className="tabs"></div>
                            { weatherloading ? <LoadingIndicator /> : 
                                <table>
                                <thead>
                                    <tr>
                                        <th >Location</th>
                                        <th >Temperature</th>
                                        <th >Last Updated</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mapWeather}
                                </tbody>
                            </table>
                            }
                        </div>
                    </div>
                    }


                </div>

                

            </div>
            <BikeParkTrails />
            </div>
        )
    }
}

export default SummerTrails
