// Import React and Component
import React, { Component } from 'react';
import './App.css';
import Lifts from './Shared/Lifts/Lifts';
import Liftstest from './Shared/Lifts/Lifts-test';
import LiftMap from './Shared/Lifts/LiftMap';
import LiftsMain from './Shared/Lifts/Lifts-Main';
import Snow from './Winter/Snow/Snow';
import Grooming from './Winter/Grooming/Grooming';
import Forecast from './Winter/Forecast/Forecast';
import WinterWeather from './Winter/Weather/Weather';
import BikeParkTrails from './Summer/BikePark/BikeParkTrails';
import Summer from './Summer/Summer';
import Winter from './Winter/Winter';
import Navbar from './Navbar';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import CheckWinterSeasonOver from './Shared/CheckWinterSeasonOver';

class App extends Component {
        constructor () {
            super();
            this.state = {
            };
        }

         // Callback to handle the season change
        handleSeasonChange = (isSummer) => {
            this.setState({
            season: isSummer ? "Summer" : "Winter",
            isSummer: isSummer,
            });
        };


  
  render() {
    return (
        <div className="App">
            <Router>
                <div>
                    <div className="topheader">
                        <header className="container">
                            <Navbar />
                        </header>
                    </div> 
                    <CheckWinterSeasonOver onSeasonChange={this.handleSeasonChange} />
                    <Switch>
                        {/* <RemoveScroll> */}
                        { this.state.isSummer ?
                        // Summer is true
                            <Route component={Summer} exact path="/" />
                        // Summer is false
                        : 
                            <Route component={Winter} exact path="/" />
                        }
                        
                        <Route component={Snow} path="/winter/snow" />
                        <Route component={LiftsMain} path="/lift" />
                        {/* <Route component={Lifts} path="/liftlist" /> */}
                        <Route component={Liftstest} exact path="/test/lifttest" />
                        {/* <Route component={LiftMap} exact path="/test/liftmap" /> */}
                        <Route component={Grooming} path="/winter/grooming" />
                        <Route component={Forecast} path="/winter/forecast" />
                        <Route component={WinterWeather} path="/winter/weather" />
                        <Route component={BikeParkTrails} path="/summer/trails" />
                        <Route component={Summer} path="/summer/" />   
                        <Route component={Winter} path="/winter/" /> 
                        {/* </RemoveScroll> */}
                    </Switch>
                </div>
            </Router>
        </div>

    );
  }
}

export default App;
