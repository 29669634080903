import React, { Component } from 'react';
import './Winter.css';
import Lifts from '../Shared/Lifts/Lifts';
import Snow from './Snow/Snow';
import Weather from './Weather/Weather';

class Winter extends Component {
    constructor () {
        super();
        this.state = {
        };
    }

render() {
return (
    <div className="App">
        <div>
            <Lifts />
        </div>
        <br />
        <div className='today--section container'>
            <div className="section-divider-horizontal container"></div>
        </div>
        <div>
            <Snow />
        </div>
        <br />
        <div className='today--section container'>
            <div className="section-divider-horizontal container"></div>
        </div>
        <div>
            <Weather />
        </div>
    </div>

);
}
}

export default Winter
