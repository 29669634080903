import React, { Component } from 'react';
import './Snow.css';
import axios from 'axios';
import convert from 'convert-units';
import Loader from 'react-loader-spinner';

const LoadingIndicator = props => {
    return (
    <div style={{
        width: "100%",
        height: "100",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
        }}
    >
    <Loader type="ThreeDots" color="var(--liftopenbox)" height="50" width="50" />
    </div>
    ) 
}

class Snow extends Component {

    constructor () {
        super();
        this.state = {
            loading: true,
            error: false,
            snowconditions: <LoadingIndicator />,
            reportdatetime: <LoadingIndicator />,
            runsopen: <LoadingIndicator />,
            runstotal: <LoadingIndicator />,
            newsnow12hours: <LoadingIndicator />,
            newsnow24hours: <LoadingIndicator />,
            newsnow48hours: <LoadingIndicator />,
            newsnow7days: <LoadingIndicator />,
            basesnowmid: <LoadingIndicator />,
            seasonsnow: <LoadingIndicator />,
            weatherreport: <LoadingIndicator />,
        };
    }

    componentDidMount () {
        this.setState({ loading: true }, () => {
            axios.get('https://fn.wblift.app/api/?type=snow')
            .then(response => {
                this.setState({ snowconditions: response.data.SnowConditions });
                this.setState({ reportdatetime: response.data.ReportDateTime + " PST"});
                this.setState({ runsopen: response.data.Runs.Open });
                this.setState({ runstotal: response.data.Runs.Total });
                this.setState({ newsnow12hours: response.data.NewSnowReadings.Overnight.Centimeters + " cm" });
                this.setState({ newsnow24hours: response.data.NewSnowReadings.TwentyFourHours.Centimeters + " cm" });
                this.setState({ newsnow48hours: response.data.NewSnowReadings.FortyEightHours.Centimeters + " cm" });
                this.setState({ newsnow7days: response.data.NewSnowReadings.SevenDays.Centimeters + " cm" });
                this.setState({ basesnowmid: response.data.BaseSnowReadings.MidMountain.Centimeters + " cm" });
                //this.setState({ seasonsnow: response.data.SeasonSnowfall.Centimeters + " cm" });
                this.setState({ seasonsnow: convert(response.data.SeasonSnowfall.Centimeters).from('cm').to('m').toFixed(2) + ' m'})
                this.setState({ weatherreport: (response.data.WeatherCommentary.TodaysReport)
                    .replace(/<\/p>\s*<p>/g, '\n\n')  // Add extra line break between paragraphs
                    .replace(/<\/?[^>]+(>|$)/g, '')  // Remove all HTML tags});
                });  
                this.setState({ loading: false });


               
            })
            .catch(error => {
                console.log(error)
                this.setState({ loading: false });
                this.setState({ error: true });
            })
        })

    }

    render() {
        const { error } = this.state;
        return (
            
            <div className="today--section container">
                {/* <CheckWinterSeasonOver /> */}
                {/* <h2>Snow Information</h2> */}
                { error ? 
                <div className="columns today--section__box">
                    <div className="column snow--section">
                        <h4 id="error">Error gathering snow data...</h4>
                    </div>
                </div> 
                :
                <div className="columns today--section__box">
                    <div className="column snow--section">
                        <h5>{this.state.newsnow12hours}</h5>
                        <p>12 Hours</p>
                    </div>
                    <div className="column snow--section">
                        <h5>{this.state.newsnow24hours}</h5>
                        <p>24 Hours</p>
                    </div>
                    <div className="column snow--section">
                        <h5>{this.state.newsnow48hours}</h5>
                        <p>48 Hours</p>
                    </div>
                    <div className="column snowend--section">
                        <h5>{this.state.newsnow7days}</h5>
                        <p>7 Days</p>
                    </div>
                </div>
                }
                
                { error ? 
                <div className="columns today--section__box">
                    <div className="column snow--section">
                        <h4 id="error">Error gathering snow data...</h4>
                    </div>
                </div> 
                :
                <div className="columns today--section__box" id="basetotal">
                    <div className="column snow--section">
                        <h5>{this.state.basesnowmid}</h5>
                        <p>Base Snow</p>
                    </div>
                    <div className="column snowend--section">
                        <h5>{this.state.seasonsnow}</h5>
                        <p>Season Total</p>
                    </div>
                </div>
                }

                { error ? 
                <div className="columns today--section__box">
                    <div className="column snow--section">
                        <h4 id="error">Error gathering snow data...</h4>
                    </div>
                </div> 
                :
                <div className="columns today--section__box">
                    <div className="column liftend--section">
                        <h5><u>Weather Report</u></h5>
                        <br></br>
                        <p style={{ whiteSpace: 'pre-line', textAlign: 'left'}}>{this.state.weatherreport}</p>
                    </div>
                </div>
                }

                <h6>Updated: {this.state.reportdatetime}</h6>

            </div>
        )
    }
    
}

export default Snow;
