import React, { useState, useEffect, useRef } from"react";
import './LiftMap.css';


const LiftMap = () => {
    const svgRef = useRef(null);
    const imageRef = useRef(null);
  
    const [liftStatuses, setLiftStatuses] = useState({});
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  
    // Fetch lift data from API
    useEffect(() => {
      const fetchLiftData = async () => {
        try {
          const response = await fetch("https://fn.wblift.app/api/?type=terrain");
          const data = await response.json();
          const liftData = data.Lifts.reduce((acc, lift) => {
            acc[lift.Name] = lift.Status.toLowerCase();
            return acc;
          }, {});
          setLiftStatuses(liftData);
        } catch (error) {
          console.error("Error fetching lift data:", error);
        }
      };
  
      fetchLiftData();
    }, []);
  
    // Update SVG lift statuses
    useEffect(() => {
      if (!svgRef.current || Object.keys(liftStatuses).length === 0) return;
  
      const updateLiftStatus = () => {
        const lines = svgRef.current.querySelectorAll("line, path");
        lines.forEach((line) => {
          const liftId = line.id;
          const liftName = liftId.replace(/_/g, " ");
          const status = liftStatuses[liftName]?.toLowerCase();
  
          line.className.baseVal =
            status === "open"
              ? "is-open"
              : status === "onhold"
              ? "is-standby"
              : ["closed", "scheduled"].includes(status)
              ? "is-closed"
              : "is-unknown";
        });
      };
  
      updateLiftStatus();
    }, [liftStatuses]);
  
    // Resize handler for dynamic SVG scaling
    useEffect(() => {
      const updateImageDimensions = () => {
        if (imageRef.current) {
          const { width, height } = imageRef.current.getBoundingClientRect();
          setImageDimensions({ width, height });
        }
      };
  
      window.addEventListener("resize", updateImageDimensions);
      updateImageDimensions(); // Initialize on mount
  
      return () => {
        window.removeEventListener("resize", updateImageDimensions);
      };
    }, []);
  
    useEffect(() => {
      if (svgRef.current && imageDimensions.width > 0 && imageDimensions.height > 0) {
        const aspectRatio = 2668 / 1235.34; // Original aspect ratio of the map
        const { width: containerWidth, height: containerHeight } = imageDimensions;
  
        let newWidth = containerWidth;
        let newHeight = newWidth / aspectRatio;
  
        if (newHeight > containerHeight) {
          newHeight = containerHeight;
          newWidth = newHeight * aspectRatio;
        }
  
        svgRef.current.setAttribute("width", newWidth);
        svgRef.current.setAttribute("height", newHeight);
      }
    }, [imageDimensions]);





  return (
    <div class="today--section container">
    {/* <div className="columns today--section__box"> */}
    <div className="live-lifts">
    <div className="lift-map" >
        <div className="map-container">
            <img ref={imageRef} src="/images/wbterrain.jpg" alt="Map Background" />
        </div>
        {/* Legend for the Lift Status */}
        <div className="map-legend">
          <div className="legend-item open">
            <span className="color-box open"></span>
            <span>Open</span>
          </div>
          <div className="legend-item standby">
            <span className="color-box standby"></span>
            <span>Standby</span>
          </div>
          <div className="legend-item closed">
            <span className="color-box closed"></span>
            <span>Closed</span>
          </div>
          <div className="legend-item unknown">
            <span className="color-box unknown"></span>
            <span>Unknown</span>
          </div>
        </div>
        <div className="lift-lines">
        <svg
            ref={svgRef}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 2668 1235.34"
            xmlSpace="preserve"
          >
  {/* <circle cx="200" cy="200" r="50" fill="blue" stroke="black" strokeWidth="3" />  
  <text
                x="200"
                y="205"
                textAnchor="middle"
                fill="white"
                fontSize="20"
                fontWeight="bold"
                dominantBaseline="middle"
              >
                10C
              </text> */}
            <g id="Lift_Lines">
                
              <line
                id="Blackcomb Gondola - Lower"
                className="is-unknown"
                strokeLinecap="round"
                strokeWidth="9"
                x1="828.2"
                x2="1066.2"
                y1="1180.2"
                y2="1518.2"
              />
              <line
                id="Blackcomb Gondola - Upper"
                className="is-unknown"
                strokeLinecap="round"
                strokeWidth="9"
                x1="714.2"
                x2="828.2"
                y1="770.2"
                y2="1180.2"
              />
              <line
                id="Whistler Village Gondola  - Lower"
                className="is-unknown"
                strokeLinecap="round"
                strokeWidth="9"
                x1="1486.2"
                x2="1330.2"
                y1="1215.2"
                y2="1532.2"
              />
              <line
                id="Whistler Village Gondola - Upper"
                className="is-unknown"
                strokeLinecap="round"
                strokeWidth="9"
                x1="1749.2"
                x2="1486.2"
                y1="687.2"
                y2="1215.2"
              />
              <line
                id="T-Bar"
                className="is-unknown"
                strokeLinecap="round"
                strokeWidth="9"
                x1="1817.2"
                x2="1936.2"
                y1="584.2"
                y2="708.2"
              />
              <line
                id="Symphony Express"
                className="is-unknown"
                strokeLinecap="round"
                strokeWidth="9"
                x1="1344.2"
                x2="1773.2"
                y1="678.2"
                y2="443.2"
              />
              <line
                id="Showcase T-Bar"
                className="is-unknown"
                strokeLinecap="round"
                strokeWidth="9"
                x1="490.1"
                x2="457.1"
                y1="460.2"
                y2="559.2"
              />
              <line
                id="Peak Express"
                className="is-unknown"
                strokeLinecap="round"
                strokeWidth="9"
                x1="1936.2"
                x2="2116.2"
                y1="739.2"
                y2="382.2"
              />
              <path
                id="PEAK 2 PEAK Gondola"
                d="M742.2,745.2c166,163,745,206,989-61"
                strokeLinecap="round"
                strokeWidth="9"
                className="is-unknown"
              />
              <line
                id="Olympic Chair"
                className="is-unknown"
                strokeLinecap="round"
                strokeWidth="9"
                x1="1362.2"
                x2="1450.2"
                y1="1100.2"
                y2="1205.2"
              />
              <line
                id="Magic Chair"
                className="is-unknown"
                strokeLinecap="round"
                strokeWidth="9"
                x1="1058.2"
                x2="1111.2"
                y1="1435.2"
                y2="1517.2"
              />
              <line
                id="Jersey Cream Express"
                className="is-unknown"
                strokeLinecap="round"
                strokeWidth="9"
                x1="491.2"
                x2="703.2"
                y1="872.2"
                y2="746.2"
              />
              <line
                id="Harmony 6 Express"
                className="is-unknown"
                strokeLinecap="round"
                strokeWidth="9"
                x1="1360.2"
                x2="1901.2"
                y1="822.2"
                y2="460.2"
              />
              <line
                id="Glacier Express"
                className="is-unknown"
                strokeLinecap="round"
                strokeWidth="9"
                x1="368.7"
                x2="476.6"
                y1="583.2"
                y2="865.2"
              />
              <line
                id="Garbanzo Express"
                className="is-unknown"
                strokeLinecap="round"
                strokeWidth="9"
                x1="1508.2"
                x2="1744.2"
                y1="1213.2"
                y2="810.2"
              />
              <line
                id="Franz's Chair"
                className="is-unknown"
                strokeLinecap="round"
                strokeWidth="9"
                x1="1792.2"
                x2="1957.2"
                y1="676.2"
                y2="877.2"
              />
              <line
                id="Fitzsimmons 8 Express"
                className="is-unknown"
                strokeLinecap="round"
                strokeWidth="9"
                x1="1312.2"
                x2="1520.2"
                y1="1533.2"
                y2="1225.2"
              />
              <line
                id="Excelerator Express"
                className="is-unknown"
                strokeLinecap="round"
                strokeWidth="9"
                x1="663.2"
                x2="591.2"
                y1="1230.2"
                y2="871.2"
              />
              <line
                id="Excalibur Gondola - Lower"
                className="is-unknown"
                strokeLinecap="round"
                strokeWidth="9"
                x1="1137.2"
                x2="1274.2"
                y1="1430.2"
                y2="1541.2"
              />
              <line
                id="Excalibur Gondola - Upper"
                className="is-unknown"
                strokeLinecap="round"
                strokeWidth="9"
                x1="678.2"
                x2="1137.2"
                y1="1236.2"
                y2="1430.2"
              />
              <line
                id="Emerald 6 Express"
                className="is-unknown"
                strokeLinecap="round"
                strokeWidth="9"
                x1="1420.2"
                x2="1717.2"
                y1="946.2"
                y2="673.2"
              />
              <line
                id="Crystal Ridge Express"
                className="is-unknown"
                strokeLinecap="round"
                strokeWidth="9"
                x1="330.2"
                x2="306.2"
                y1="1139.2"
                y2="738.2"
              />
              <line
                id="Creekside Gondola"
                className="is-unknown"
                strokeLinecap="round"
                strokeWidth="9"
                x1="2341.2"
                x2="2015.2"
                y1="1463.2"
                y2="1140.2"
              />
              <line
                id="Bubly Tube Park"
                className="is-unknown"
                strokeLinecap="round"
                strokeWidth="9"
                x1="1101.2"
                x2="1132.2"
                y1="1336.2"
                y2="1391.2"
              />
              <line
                id="Catskinner Express"
                className="is-unknown"
                strokeLinecap="round"
                strokeWidth="9"
                x1="729.2"
                x2="915.2"
                y1="756.2"
                y2="960.2"
              />
              <line
                id="Big Red Express"
                className="is-unknown"
                strokeLinecap="round"
                strokeWidth="9"
                x1="2003.2"
                x2="1766.2"
                y1="1124.2"
                y2="682.2"
              />
              <line
                id="7th Heaven Express"
                className="is-unknown"
                strokeLinecap="round"
                strokeWidth="9"
                x1="556.6"
                x2="1005.6"
                y1="554.7"
                y2="707.7"
              />
            </g>
          </svg>
        </div>
    </div>
    </div>
    {/* </div> */}
    {/* <h6>Updated: test</h6> */}
    </div>
  );
};

export default LiftMap;