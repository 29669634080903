import React from 'react';
import { Link } from 'react-router-dom';
import ThemeToggle from './ThemeToggle'; // Adjust the path as necessary





    const Navigation = (props) => {
      function toggleBurgerMenu() {
        document.querySelector('.navbar-menu').classList.toggle('is-active');
      }
 
  
    return (
      <nav className='navbar' role='navigation' aria-label='main navigation'>
        <div className='navbar-brand'>
          <a href='/' className='navbar-item'>
            <img
              src='/logo192.png'
              alt='Logo'
              width='WBLift'
              height='40'
            />
          </a>

          <a role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasic"
          onClick={toggleBurgerMenu}>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
        </div>
        <div id='navbarBasic' className='navbar-menu'>
          
            <div className='navbar-end'>
            <Link className='navbar-item' onClick={toggleBurgerMenu} to='/'>
                Home
              </Link>
              <Link className='navbar-item' onClick={toggleBurgerMenu} to='/lift'>
                Lift
              </Link>
              <Link className='navbar-item' onClick={toggleBurgerMenu} to='/winter/snow'>
                Snow
              </Link>
              <Link className='navbar-item' onClick={toggleBurgerMenu} to='/winter/grooming'>
                Grooming
              </Link>
               {/* Summer Dropdown */}
               <div className='navbar-item has-dropdown is-hoverable'>
                  <Link className='navbar-link' onClick={toggleBurgerMenu} to='/summer/'>
                    Summer
                  </Link> 
                  <div className='navbar-dropdown'>
                    <Link className='navbar-item' onClick={toggleBurgerMenu} to='/summer/trails'>
                      Bike Trails
                    </Link>
                  </div>
                </div>
                {/* Theme Toggle Button */}
                  <ThemeToggle />

              </div>
          
        </div>
      </nav>
    )
  }

  export default Navigation;