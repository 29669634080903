import React, { Component } from "react";
import axios from "axios";
import { Parser } from "htmlparser2";
import Loader from "react-loader-spinner";
import { parse, format } from 'date-fns'

// Loader Component
const LoadingIndicator = () => (
    <div className="loading-indicator">
        <Loader className="loading-spinner" type="ThreeDots" color="var(--liftopenbox)" height={50} width={50} />
    </div>
);

// Utility function to format dates
const formatDate = (dateString) => {
    // console.log("Original Date String: " + dateString);

    // Normalize the date string by removing multiple spaces
    const normalizedDate = dateString.replace(/\s+/g, ' ').trim();
    // console.log("Normalized Date String: " + normalizedDate);

    // Try parsing the date using date-fns with a custom format
    const parsedDate = parse(normalizedDate, "MMM dd yyyy hh:mma", new Date());
    // console.log("Parsed Date: ", parsedDate);

    // Check if the parsed date is valid
    if (isNaN(parsedDate)) {
        // console.error("Invalid date format: ", normalizedDate);
        return "N/A"; // Return "N/A" if date parsing fails
    }

    const now = new Date();
    const isToday =
        parsedDate.getDate() === now.getDate() &&
        parsedDate.getMonth() === now.getMonth() &&
        parsedDate.getFullYear() === now.getFullYear();

    const time = format(parsedDate, "h:mma"); // Format time in 12-hour format

    if (isToday) {
        return time; // Return only time if today
    }

    // Format the date if it's not today (e.g., "3:00PM (1 Dec)")
    const formattedDate = format(parsedDate, " (dd MMM)");
    return `${time} (${formattedDate})`; // Return time with date if not today
};

const getWindDirection = (degrees) => {
    if (degrees < 0 || degrees > 360) return "N/A"; // Ensure valid degrees

    const directions = [
        "North", "North East", "East", "South East", "South", "South West", "West", "North West"
    ];

    const index = Math.round(((degrees % 360) / 45)) % 8; // Map degrees to a 45-degree interval
    return directions[index];
};



// Configuration for weather stations
const weatherStationURLs = [
    { id: 160, name: "Valley" },
    { id: 203, name: "Roundhouse" },
    { id: 210, name: "Pig Alley" },
    { id: 201, name: "Peak" },
    { id: 109, name: "Harmony" },
    { id: 206, name: "Symphony" },
    { id: 110, name: "Zoo" },
    { id: 220, name: "Catskinner" },
    { id: 140, name: "Crystal" },
    { id: 100, name: "Horstman" },
];

const locationMapping = {
    "Valley": { friendlyname: "Whistler Village", elevation: "670m", Mountain: "Valley" },
    "Whistler Alpine (SB)": { friendlyname: "Roundhouse Lodge", elevation: "1835m", Mountain: "Whistler" },
    "Pig Alley": { friendlyname: "Pig Alley", elevation: "1635m", Mountain: "Whistler" },
    "Peak": { friendlyname: "Whistler Peak", elevation: "2180m", Mountain: "Whistler" },
    "Ridge": { friendlyname: "Harmony Ridge", elevation: "2100m", Mountain: "Whistler" },
    "Symphony": { friendlyname: "Symphony", elevation: "2035m", Mountain: "Whistler" },
    "Blackcomb Alpine (JC)": { friendlyname: "Rendezvous Lodge", elevation: "1860m", Mountain: "Blackcomb" },
    "Catskinner New": { friendlyname: "Catskinner", elevation: "1570m", Mountain: "Blackcomb" },
    "Crystal Chair": { friendlyname: "Crystal", elevation: "1825m", Mountain: "Blackcomb" },
    "Horstman Hut": { friendlyname: "Horstman Hut", elevation: "2250m", Mountain: "Blackcomb" },
};

class WinterWeather extends Component {
    state = {
        vWeather: [],
        mountainfilter: "All",
        weatherloading: true,
        weathererror: false,
    };

    componentDidMount() {
        // Fetch weather data
        const requests = weatherStationURLs.map((station) =>
            axios.get(`https://fn.wblift.app/api/?type=weatherstation&id=${station.id}`)
        );

        axios
            .all(requests)
            .then((responses) => {
                const WeatherArray = responses.map((response, index) => {
                    let weatherjson = null;
                    const parser = new Parser(
                        {
                            onopentag(name, attributes) {
                                if (name === "weatherstation") {
                                    weatherjson = { $: attributes, Data: [] };
                                }
                                if (name === "data" && weatherjson) {
                                    weatherjson.Data.push({ $: attributes });
                                }
                            },
                        },
                        { decodeEntities: true }
                    );
                    parser.write(response.data);
                    parser.end();

                    if (weatherjson) {
                        const locationName = weatherjson.$.name;
                        const locationData = locationMapping[locationName] || {
                            friendlyname: locationName,
                            elevation: "Unknown",
                        };

                        return {
                            
                            ID: index,
                            Location: locationName,
                            FriendlyName: locationData.friendlyname,
                            CurrentTemp: weatherjson?.Data[0]?.$?.temp + "°C" || "N/A",
                            // Updated: weatherjson?.Data[0]?.$?.updated || "N/A",
                            Updated: formatDate(weatherjson?.Data[0]?.$?.updated || "N/A"), // Format the date here
                            elevation: locationData.elevation,
                            elevationValue: parseInt(locationData.elevation) || 0, // Numeric elevation
                            Mountain: locationData.Mountain,
                            WindAverage: weatherjson?.Data[0]?.$?.windavg + "km/h" || "N/A",
                            WindMax: weatherjson?.Data[0]?.$?.windmax + "km/h" || "N/A",
                            WindDegree: weatherjson?.Data[0]?.$?.winddirection || "N/A",
                            WindDirection: getWindDirection(weatherjson?.Data[0]?.$?.winddirection || "N/A",),
                            SnowBase: weatherjson?.Data[0]?.$?.snowbase + "cm" || "N/A",
                            SnowNew: weatherjson?.Data[0]?.$?.snownew + "cm" || "N/A",
                        };
                    }
                    return null;
                });

                // Sort WeatherArray by elevation descending before setting state
                WeatherArray.sort((a, b) => b.elevationValue - a.elevationValue);
                
                this.setState({ vWeather: WeatherArray.filter(Boolean), weatherloading: false });
            })
            .catch((error) => {
                console.error("Error fetching weather data:", error);
                this.setState({ weatherloading: false, weathererror: true });
            });
    }

    mountainSelect = (event) => {
        this.setState({ mountainfilter: event.target.value });
    };

    // Helper function to group data by elevation range
    groupWeatherData = (data) => {
        const groups = {
            "Valley": [],
            "Mid Mountain": [],
            "Top Mountain": [],
        };

        data.forEach((weather) => {
            const elevationValue = parseInt(weather.elevation.replace("m", ""), 10); // Extract numeric elevation
            if (elevationValue < 900) {
                groups["Valley"].push(weather);
            } else if (elevationValue <= 1950) {
                groups["Mid Mountain"].push(weather);
            } else {
                groups["Top Mountain"].push(weather);
            }
        });

        return groups;
    };

    render() {
        const { vWeather, weatherloading, weathererror, mountainfilter } = this.state;

        const uniqueMountains = ["All", ...new Set(vWeather.map((weather) => weather.Mountain))];
        const filteredWeather = vWeather.filter((weather) =>
            mountainfilter === "All" ? true : weather.Mountain === mountainfilter
        );

        // Group filtered weather data by elevation range
        const groupedData = this.groupWeatherData(filteredWeather);
        // Define the order of groups
        const groupOrder = ["Top Mountain", "Mid Mountain", "Valley"];

        return (
            <div className="today--section container">
            <div className="columns today--section__box">
            <div className="column liftsend--section">
                {weathererror ? (
                    <div className="error-message">Error gathering weather data...</div>
                ) : (
                        <>
                        <h5>Current Weather</h5>
                        <div className="filterbuttons">
                            {uniqueMountains.map((mountain, index) => (
                                <button
                                    key={index}
                                    className={`FilterBtn ${
                                        mountainfilter === mountain ? "buttonActive" : ""
                                    }`}
                                    value={mountain}
                                    onClick={this.mountainSelect}
                                >
                                    {mountain}
                                </button>
                            ))}
                        </div>
                        <div id="tabsContainer" className="tabs"></div>
                        {weatherloading ? (
                            <LoadingIndicator />
                        ) : (
                            <table id="weatherTable">
                                <thead id="weatherTitle">
                                    <tr>
                                        <th id="locationTitle">Location</th>
                                        <th id="tempTitle">Temp</th>
                                        <th id="windavgTitle">Wind (Avg)</th>
                                        <th id="windmaxTitle">Wind (Max)</th>
                                        <th id="winddirectionTitle">Wind Direction</th>
                                        <th id="mountainTitle">Mountain</th>
                                        <th id="elevationTitle">Elev.</th>
                                        <th id="updatedTitle">Updated</th>
                                    </tr>
                                </thead>
                                {/* Render groups in the specified order */}
                                {groupOrder.map((group) => (
                                    groupedData[group]?.length > 0 && ( // Only render if group has entries
                                        <React.Fragment key={group}>
                                            <br />
                                            <thead>
                                                <tr>
                                                    <th colSpan="8" className="group-header" id="grouping_tit">
                                                        <u>{group}</u>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody id="weatherData">
                                                {groupedData[group].map((weather) => (
                                                    <tr key={weather.ID}>
                                                        <th id="locationData">{weather.FriendlyName}</th>
                                                        <th id="tempData">{weather.CurrentTemp}</th>
                                                        <th id="windavgData">{weather.WindAverage}</th>
                                                        <th id="windmaxData">{weather.WindMax}</th>
                                                        <th id="winddirectionData">{weather.WindDirection}</th>
                                                        <th id="mountainData">{weather.Mountain}</th>
                                                        <th id="elevationData">{weather.elevation}</th>
                                                        <th id="updatedData">{weather.Updated}</th>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </React.Fragment>
                                    )
                                ))}
                            </table>
                        )}
                        </>
                )}
            </div>
            </div>
            </div>
        );
    }
}

export default WinterWeather;
