import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';


const CheckWinterSeasonOver = ({ onSeasonChange }) => {
    const [season, setSeason] = useState({
      season: "Winter",
      isSummer: false,
    });

    const location = useLocation();  // Access the current location/path

    // Define a list of paths where the notice should be shown
    const WinterPages = location.pathname.startsWith('/winter/');  
    const SummerPages = location.pathname.startsWith('/summer/'); 
  
    useEffect(() => {
        const getLastMondayBeforeMay25 = (year) => {
          const may25 = new Date(year, 4, 25); // May 25th of the current year
          const dayOfWeek = may25.getDay(); // Get the day of the week
          const daysToSubtract = (dayOfWeek === 0 ? 6 : dayOfWeek - 1); // Calculate last Monday before May 25
          const lastMonday = new Date(may25.setDate(may25.getDate() - daysToSubtract));
          return lastMonday;
        };
    
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
    
        // Set winter start to November 1 and calculate last Monday before May 25 (Winter end)
        const winterStart = new Date(currentYear, 10, 1); // November 1st of the current year
        const winterEnd = getLastMondayBeforeMay25(currentYear); // Last Monday before May 25
    
        const formatDate = (date) => {
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const day = date.getDate().toString().padStart(2, '0');
          return `${month}-${day}`;
        };
    
        const formattedWinterStart = formatDate(winterStart);
        const formattedWinterEnd = formatDate(winterEnd);
        const formattedDate = formatDate(currentDate);
    
        // Determine if it's summer or winter
        if (formattedDate >= formattedWinterEnd && formattedDate <= formattedWinterStart) {
          setSeason({
            season: "Summer",
            isSummer: true
          });
        } else {
          setSeason({
            season: "Winter",
            isSummer: false
          });
        }
    
      }, []);

      // Trigger parent callback after state update
        useEffect(() => {
            if (onSeasonChange) {
            onSeasonChange(season.isSummer);
            }
        }, [season, onSeasonChange]); // Run this effect whenever `season` changes

        return (
            <div class="today--section container">
                {season.isSummer && WinterPages && (
                <div className="ClosedNotice">
                    <h3>Notice: The Winter season has ended. This data is not updated during the Summer.</h3>
                </div>
                )}
                {!season.isSummer && SummerPages &&  (
                <div className="ClosedNotice">
                    <h3>Notice: The Summer season has ended. This data is not updated during the Winter.</h3>
                </div>
      )}
            </div>
        );

};


export default CheckWinterSeasonOver;
