import React, { Component } from 'react';
import './Grooming.css';
import axios from 'axios';
import Loader from 'react-loader-spinner';


const LoadingIndicator = props => {
    return (
    <div style={{
        width: "100%",
        height: "100",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
        }}
    >
    <Loader type="ThreeDots" color="var(--liftopenbox)" height="50" width="50" />
    </div>
    ) 
}

class Grooming extends Component {
    constructor () {
        super();
        this.state = {
            vTrails: [],
            vZones: [], //
            vDate: '',
            loading: true,
            error: false,
            search:null,
        };
    }

    componentDidMount() {

        axios.get('https://fn.wblift.app/api/?type=terrain')
        
        .then(response => {
            var resp = [];
            resp = response.data.GroomingAreas;

            var TrailArray = [];

            response.data.GroomingAreas.forEach((el1) => {
                var area = el1.Name;
                el1.Trails.forEach((el) => {
                    //console.log(el.Id, el.Name, area, el.Difficulty, el.IsOpen, el.IsGroomed)
                    if (el.Difficulty === "Green"){
                        var DifficultyIcon = <i className="fa-solid fa-circle"></i>
                    }
                    if (el.Difficulty === "Blue"){
                        var DifficultyIcon = <i className="fa-solid fa-square"></i>
                    }
                    if (el.Difficulty === "Black"){
                        var DifficultyIcon = <i className="fa-solid fa-diamond"></i>
                    }
                    if (el.Difficulty === "DoubleBlack"){
                        var DifficultyIcon = <><i className="fa-solid fa-diamond"></i><i className="fa-solid fa-diamond"></i></>
                    }
                    if (el.Difficulty === "TerrainPark"){
                        var DifficultyIcon = <i class="fa-solid fa-ticket-simple"></i>
                    }
                    if (el.IsGroomed === true) {
                        var GroomIcon = <i class="fa-solid fa-snowplow"></i>
                    }
                    if (area !== "Backcountry Uphill Access Routes"){
                    TrailArray.push({ID: el.Id, Trail: el.Name, Zone: area, Difficulty: el.Difficulty, Icon: DifficultyIcon, isOpen: el.IsOpen, Groomed: el.IsGroomed, GroomingIcon: GroomIcon})
                    }
                })
            })

            // this.setState({ vTrails: TrailArray })
            // this.setState({ vDate: new Date(response.data.Date).toLocaleString('en-US',{timeZoneName: "short"})});
            // this.setState({ loading: false }); 

            // Sorts Trails Array to A-Z
            TrailArray = TrailArray.sort((a, b) => {
                if (a.Zone < b.Zone) return -1;
                if (a.Zone > b.Zone) return 1;
                if (a.Trail < b.Trail) return -1;
                if (a.Trail > b.Trail) return 1;
                return 0;
            });

            var zones = Array.from(
                new Set(TrailArray.map(item => item.Zone))
            ).map(Zone => {
                return {Zone}
            })
            zones.push({Zone: "All"})


            // Sort zones array, ensuring "All" is always first
            zones = zones.sort((a, b) => {
                if (a.Zone === "All") return -1; // "All" should come first
                if (b.Zone === "All") return 1;  // "All" should come first
                return a.Zone.localeCompare(b.Zone); // Sort alphabetically
            });


            // Sets Variables for use later
            this.setState({ vTrails: TrailArray })
            this.setState({ vZones: zones })
            // this.setState({ vBikeParkNotes: bikeparktrails.Notes })
            this.setState({ vDate: new Date(response.data.Date).toLocaleString('en-US',{timeZoneName: "short"})});
            this.setState({ loading: false }); 

            })
        .catch(error => {
            console.log(error);
            console.log("Error Here, sending Error to client");
            this.setState({ loading: false });
            this.setState({ error: true})
        });


    };

   

  searchSpace=(event)=>{
    let keyword = event.target.value;
    this.setState({search:keyword})
  }
  // Sets Zone Filter on Button Click
  zoneSelect=(event)=>{
    let keyword = event.target.value;
    this.setState({zonefilter:keyword})
  }

    render() {
        const { loading } = this.state;
        const { error } = this.state;
        var vTrails = this.state.vTrails;

        const mapTrails = vTrails
        .filter((vTrails) => {
            // Filter by Zone selection
            if (this.state.zonefilter == null || this.state.zonefilter === "All")
                return vTrails
            else if (vTrails.Zone.includes(this.state.zonefilter))
                return vTrails
        })
        .filter((vTrails) => {
            // Filter by Search Bar
            if(this.state.search == null)
            return vTrails
            else if(vTrails.Trail.toLowerCase().includes(this.state.search.toLowerCase()))
            return vTrails
        })
        .map(function(vTrails){

            return(
                <React.Fragment key={vTrails.ID}>
                <tr>
                    <th>{vTrails.Trail}</th>
                    <th>{vTrails.Zone}</th>
                    <th id='difficultyicon'>{vTrails.Icon}</th>
                    {/* <th>{vTrails.Groomed.toString()}</th> */}
                    <th id='difficultyicon'>{vTrails.GroomingIcon}</th>
                </tr>
               </React.Fragment>
            )
        })

        // Sets Filtering Buttons as Active/Deactive for CSS purposes
        var btnElList = document.querySelectorAll('.FilterBtn');
        btnElList.forEach(btnEl => {
            btnEl.addEventListener('click', () => {
                document.querySelector('.buttonActive')?.classList.remove('buttonActive');
                btnEl.classList.add('buttonActive');
            })
        })


        return (
            <div className="today--section container">
                {/* <h2>Lift Information</h2> */}
                {/* <CheckWinterSeasonOver /> */}
                { error ? 
                // error is true:
                <div className="columns today--section__box">
                    <div className="column lifts--section">
                        <h4 id="error">Error gathering lift data...</h4>
                    </div>
                </div>
                :
                // error is false:
                <div className="columns today--section__box">
                    <div className="column liftsend--section">
                    <h5>Trails</h5>
                    <div className="filterbuttons">
                    {this.state.vZones &&
                        this.state.vZones.map((type, index) => (
                            <>
                            <button className="FilterBtn" key={index} value={type.Zone} onClick={this.zoneSelect}>
                                {type.Zone}
                            </button>
                            </>
                        ))}
                    </div>
                    <input id="searchbox" type="text" className="input is-rounded" placeholder="Enter Trail Name to Search" onChange={(e)=>this.searchSpace(e)} />
                        { loading ? <LoadingIndicator /> : 
                            <table>
                                <thead>
                                    <tr>
                                        <th >Trail Name</th>
                                        <th >Zone</th>
                                        <th id="centerhead">Difficulty</th>
                                        <th id="centerhead">Groomed</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mapTrails}
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
                }
                <h6>Updated: {this.state.vDate}</h6>
            </div>
        )
    }
}

export default Grooming
